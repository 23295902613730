h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div,
li,
ul,
ol,
button,
input,
textarea {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 16px;
  letter-spacing: 0px;
  text-align: center;
  opacity: 1;
}
.bold {
  font-weight: bold;
}

.left-sidebar {
  padding-left: 1%;
  padding-right: 0.5%;
  padding-top: 1%;
}
.col-lg-3.left-sidebar{
  width: 23.2%;
}

.col-lg-9.right-sidebar{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
}

.right-sidebar {
  padding-left: 0.5%;
  padding-right: 1%;
  padding-top: 1%;
}



.cart {
  background-color: rgb(139 139 155 / 30%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 15px;
}

.left-menu {
  height: 96vh;
}
.footer {
  height: 10vh;
}

.header {
  height: 11vh;
}

.video {
  height: 68vh;
}
.header.giris-slogan{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  align-items: center;
}

.header.cart .hos-geldiniz {
  font-weight: bold;
margin-left: 10vw;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  opacity: 1;
  font-size: 56px;
}
.header.cart .yonetim-ad {
  font-weight: bold;
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.left-sidebar .left-menu {
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 8%;
  padding-bottom: 8%;
}

.left-sidebar .left-menu div:nth-child(1) h4 {
  font-size: 1.2vw;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.left-sidebar .left-menu div:nth-child(1) img {
    width: 8vw;
    margin-left: 120%;
    margin-top: 1vh;
    transform: translate(-110%);
    -webkit-transform: translate(-110%);
    -moz-transform: translate(-110%);
    -ms-transform: translate(-110%);
    -o-transform: translate(-110%);
}

.left-sidebar .left-menu div:nth-child(2) {
  text-align: center;
}

.left-sidebar .left-menu div:nth-child(2) h5 {
font-size:large;
font-weight: 100;
}

.left-sidebar .left-menu div:nth-child(3) {
  text-align: center;
}
.left-sidebar .left-menu div:nth-child(3) h5 {
  font-size: 2vw;
  margin-top: 4vh;
}
.left-sidebar .left-menu div:nth-child(4) {
  text-align: center;
}
.left-sidebar .left-menu div:nth-child(4) span {
  font-size: 2vw;
  margin-top: 4vh;
}
.cart.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.guncel-button {
  font-size: 2vw;
  font-weight:400;
  width: 159px;
  margin-left: 1vw;
  background: #7d92b1 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border: none;
}

.marquee-guncel-haberler .rfm-child h5{
  font-size: 1.7vw;
  font-weight: 400;
  opacity: 1;
}

.hava-durumu-widget {
  width: 100%;
  border-radius: 10px;
  border: 1px solid transparent;
  overflow: hidden;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.hava-durumu-widget a,
.hava-durumu-widget span,
.hava-durumu-widget h1,
.hava-durumu-widget h2,
.hava-durumu-widget h3,
.hava-durumu-widget h4,
.hava-durumu-widget h5,
.hava-durumu-widget h6,
.hava-durumu-widget p,
.hava-durumu-widget div,
.hava-durumu-widget li,
.hava-durumu-widget ul,
.hava-durumu-widget ol,
.hava-durumu-widget button,
.hava-durumu-widget input,
.hava-durumu-widget textarea {
}

.left-sidebar .left-menu .hava-durumu-widget div:nth-child(2) {
  text-align: center;
}
table tbody td,
table tbody th.weather-icon-container {
  color: #ffffff !important;
}
a.ms-informer-link {
  text-decoration: none !important;
  width: 100%;
}
.left-menu.cart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.wi .wi-day-showers {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hava-durumu-widget table tbody tr th i {
  font-size: 25px !important;
}

table tbody tr th i::before {
  font-size: 2.5rem;
}
.mySwiper{
  height: 250px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border: 10px solid #4E5C6F;
  background-color: #4E5C6F;

}

.currency-div{
background-color: #465469;
min-height: 10vh;
padding-right: 1vw;
border-radius: 10px;
}

.dolarbg.currency-div{
background-image: url("/public/images/usd.png");
background-repeat: no-repeat;
background-size: 370px 370px;
background-position: center center;
padding-bottom: 2vh;
padding-top: 2vh;
height: 100%;
border: 1px solid #4E5C6F;


}
.eurobg.currency-div{
    background-image: url("/public/images/eur.png");
    background-repeat: no-repeat;
    background-size: 370px 370px;
    background-position: center center;
    padding-bottom: 2vh;
    padding-top: 2vh;
    height: 100%;
    border: 1px solid #4E5C6F;

    }
.gbpbg.currency-div{
    background-image: url("/public/images/gbp.png");
    background-repeat: no-repeat;
    background-size: 370px 370px;
    background-position: center center;
    padding-bottom: 2vh;
    padding-top: 2vh;
    height: 100%;
    border: 1px solid #4E5C6F;

    }
.aznbg.currency-div{
    background-image: url("/public/images/azn.png");
    background-repeat: no-repeat;
    background-size: 370px 370px;
    background-position: center center;
    padding-bottom: 2vh;
    padding-top: 2vh;
    height: 100%;
    border: 1px solid #4E5C6F;

    }

    .currency-name {
      font-weight: 600;
      font-size: 2.5rem;
      text-align: start;
      margin-left: 1vw;
      ;
  
  }
  .currency-tl-value{
    text-align: start;
    margin-left: 1.2vw;
  }

.currency-div p{
    font-size: 2rem;
}

.auto-date-div{
  display: flex;
  flex-direction: column;
  margin-top: -8vh;

}

.auto-date-tarih{
font-size: 4vh;
font-weight: 600;

}

.auto-date-gun{
  font-size: 3vh;

}

.auto-date-saat{
  font-size: 3vh;
  font-weight: 600;

}

.currency-bg-blue{
    background: #7d92b1 0% 0% no-repeat padding-box;
    padding: 10px;
    border-radius: 10px;

}
.contact-div{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 3vh;
}

div.contact-div h5{
  font-size: 1.3vw;
  font-weight: 100;
  opacity: 1;
  margin: 0;
  color: white;


}
.screenoneone{
  width: 100%;
  max-height: 300px;
  height: 27vh;
}

